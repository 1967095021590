import React from 'react';
import Fade from 'react-reveal/Fade';
import { Section, Container, Link } from 'components';
import './NotFound.scss';
import { pages } from 'config/pages';
import { notFoundData } from 'mock/data.js';

const NotFound = () => {
  const { label } = pages['404'];

  return (
  <Section id={`tt-${label}`} additionalClass={'not-found'}>
    <Fade left duration={1000} delay={200} distance={'30px'}>
      <div className={'not-found__wrapper'}>
        <Container
          side
          title={notFoundData.title}
          description={notFoundData.description}
          theme={'light'}
        />
        <Link
          to={'/'}
          button
          shadow
          additionalClass={'transition--fast link--borderless'}
        >
          {notFoundData.link}
        </Link>
      </div>
    </Fade>
  </Section>
  );
};

export default NotFound;
