import React from "react"
import Layout from 'components/layout';
import { Navbar, Footer } from '../components';
import NotFound from 'views/404';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages['404'];
const url = `${config.siteUrl}/404/`;
const canonical = `${url}`;

const NotFoundPage = () => {
  return (
    <Layout {...{ page, canonical, url }}>
      <Navbar />
      <NotFound />
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
